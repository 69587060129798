import React, { Fragment, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { ApiCallStatus } from "../../utils/constants";
import Orders from "../../components/Orders";
import { cloneDeep } from "lodash";
import { fetchOrdersData } from "../PageFunctions/orderFunctions";

import { useAuth0 } from "@auth0/auth0-react";

const ModalName = Object.freeze({
	MarkAsPaid: Symbol(1),
});

const OrdersPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();
	const [orders, setOrders] = useState([]);

	const [orderToEdit, setOrderToEdit] = useState(null);

	const [pageNumber, setPageNumber] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(18);

	const [openModal, setOpenModal] = useState(null);

	const [fetchOrdersDataCallStatus, setFetchOrdersDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const reloadPage = () => navigate(0);


	const fetchOrdersDataAsync = async (pageNumberParam, pageSizeParam) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchOrdersData({
			auth0Token,
			pageNumber: pageNumberParam ?? "1",
			pageSize: pageSizeParam ?? "18",
			setOrders,
			setPageNumber,
			setTotalResults,
			setStatusInProgress: () => setFetchOrdersDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchOrdersDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchOrdersDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchOrdersDataCallStatus(ApiCallStatus.Error)
		});
	};

	const authorizedPageLoad = async () => {
		const searchParams = new URLSearchParams(location.search);
		const pageNumberParam = searchParams.get('pageNumber') ?? "1";
		const pageSizeParam = "18";

		await Promise.all([
			fetchOrdersDataAsync(pageNumberParam, pageSizeParam)
		]);
	};

	const onBackClick = () => {
		return navigate(-1);
	};

	const onPageNumberChange = async (_, {activePage}) => {
		await updateUrlQueryValue("pageNumber", activePage);
	};

	const openMarkAsPaidModal = () => {
		setOpenModal(ModalName.MarkAsPaid);
	};

	const closeModals = () => {
		setOpenModal(null);
	};

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const updateUrlQueryValue = async (key, value) => {
		const searchParams = new URLSearchParams(location.search);
		setFetchOrdersDataCallStatus(ApiCallStatus.NotStarted);
		const currentParams = Object.fromEntries([...searchParams]);
		const paramsClone = cloneDeep(currentParams);

		paramsClone[key] = value;
		await navigate({
			pathname: '/orders',
			search: `?${createSearchParams(paramsClone)}`
		});
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	useEffect(() => {
		scrollTop();

		const searchParams = new URLSearchParams(location.search);

		const pn = searchParams.get("pageNumber");

		if (!!pn) {
			fetchOrdersDataAsync(pn);
		}
	}, [location]);

	const allLoadingStates = [
		fetchOrdersDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<Orders
				orders={orders}
				totalResults={totalResults}
		        pageNumber={pageNumber}
		        pageSize={pageSize}
		        onPageNumberChange={onPageNumberChange}
				orderToEdit={orderToEdit}
				setOrderToEdit={setOrderToEdit}
				showMarkAsPaidModal={openModal === ModalName.MarkAsPaid}
				openMarkAsPaidModal={openMarkAsPaidModal}
				closeModals={closeModals}
		        onBackClick={onBackClick}
				reloadPage={reloadPage}
			/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default OrdersPage;
